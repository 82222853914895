<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Display Name"
          class="mt-5"
        />

        <translatable-editor
          v-model="form.introduction"
          label="Brand Introduction"
        />

        <v-text-field
          v-model="form.slug"
          label="brand slug"
          placeholder="e.g. apple, medicine-one, fit-yoga"
          :rules="[ required ]"
        />

        <v-select
          v-model="form.nature"
          placeholder="Nature"
          :items="natureOptions"
          item-text="title"
          item-value="value"
        ></v-select>

        <v-select
          v-show="requireMid"
          v-model="form.merchant_id"
          placeholder="Merchant"
          :items="merchantOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>

        <v-select
          v-model="form.distributor_id"
          placeholder="Assigned Distributor"
          :items="distributorOptions"
          clearable
          item-text="label"
          item-value="value"
          outlined
          dense
        ></v-select>

        <p>If you assign a distributor, only their users can use it.</p>

        <div class="demo-space-x">
          <v-switch
            v-model="form.active"
            color="primary"
            :label="form.active ? 'Active' : 'Inactive'"
            :disabled="disabledFields.indexOf('active') >= 0"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import {
useAlphaDash, useDistributor, useSlugify, useTranslatable,
} from '@/composables'
import merchantStore from '@/modules/merchant/store'
import store from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslatableEditor, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: useTranslatable(props.resource, 'name'),
      introduction: useTranslatable(props.resource, 'introduction'),
      nature: props.resource ? props.resource.nature : 'tangible',
      slug: props.resource ? props.resource.slug : '',
      merchant_id: props.resource ? props.resource.merchants?.[0]?.id : null,
      distributor_id: props.resource ? props.resource.distributor_id : null,
      active: props.resource ? props.resource.active : false,
    }

    const { distributorOptions, fetchDistributors } = useDistributor()
    fetchDistributors({ take: 999 })

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const requireMid = computed(() => ['service', 'activity'].includes(form.value.nature))

    const validate = () => {
      form.value.slug = useAlphaDash(useSlugify(form.value.slug))
      form.value.merchant_id = requireMid ? form.value.merchant_id : null
      if (formElem.value.validate()) {
        emit('submit', form.value)
      }
    }

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `#${merchant.id} - ${t(merchant.name)}`, value: merchant.id })))

    return {
      form,
      formElem,

      validate,
      required,

      requireMid,
      merchantOptions,
      natureOptions: store.state.natureOptions,
      distributorOptions,
    }
  },
}
</script>
